import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Row, Col, Container } from "reactstrap";
import NevBar from "./Component/NevBar";
import Menus from "./Component/Menus";
import Home from "./Component/Home";
import AddCourse from "./Component/AddCourse";
import AllCourse from "./Component/Allcourse";
import Error from "./Component/Error";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  // const btnHandler = () => {
  //   toast.success("Form has been submited", {
  //     position: "buttom-center",
  //     autoClose: 10000,
  //   });
  // };
  return (
    <>
      <Router>
        <ToastContainer />
        <Container>
          <NevBar />
          <Row>
            <Col md={4}>
              <Menus />
            </Col>
            <Col md={8}>
              <Route path="/" component={Home} exact />
              <Route path="/add-course" component={AddCourse} exact />{" "}
              <Route path="/view-course" component={AllCourse} exact />
            </Col>
          </Row>
        </Container>
      </Router>
    </>
  );
}

export default App;
