import React from "react";
const Menus = () => {
  return (
    <>
      <div className="card text-center" style={{ width: "18rem" }}>
        <div className="card-body">
          <h5 className="card-title">Aditya Kumar</h5>
          <p className="card-text">
            working as a Electrical Engineer At delhi Airport Since 2016
          </p>
          <a
            href="https://github.com/adityaparsad01"
            className="btn btn-primary"
          >
            Hire Me
          </a>
        </div>
      </div>
    </>
  );
};

export default Menus;
