import React from "react";
import { Nav } from "reactstrap";
import { Link } from "react-router-dom";

const NevBar = () => {
  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/">
          WaitOminute
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto mx-2 px-2 ">
            <Link className="btn btn-danger mx-2" tag="a" to="/" action="true">
              Home
            </Link>
            <Link
              className="btn btn-primary mx-2"
              tag="a"
              to="/add-course"
              action="true"
            >
              Add Course
            </Link>
            <Link
              className="btn btn-secondary mx-2"
              tag="a"
              to="/view-course"
              action="true"
            >
              View Course
            </Link>
            <Link
              className="btn btn-success mx-2"
              tag="a"
              to="#!"
              action="true"
            >
              About
            </Link>
            <Link className="btn btn-info mx-2" tag="a" to="#!" action="true">
              Contect
            </Link>
          </ul>
        </div>
      </Nav>
    </>
  );
};

export default NevBar;
